import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import * as userTheme from "../data/theme.json";

const openSans = `'Open Sans', sans-serif`;
const asap = `'Asap Condensed', sans-serif`;

export type ThemeWithAccent = Theme & {
    accent: {
        light?: string;
        main?: string;
        dark?: string;
        contrastText?: string;
    }
}

const theme =  {...responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            light: userTheme.primary.light,
            main: userTheme.primary.main,
            dark: userTheme.primary.dark,
            contrastText: userTheme.primary.contrastText,
        },
        secondary: {
            light: userTheme.secondary.light,
            main: userTheme.secondary.main,
            dark: userTheme.secondary.dark,
            contrastText: userTheme.secondary.contrastText,
        },
    },
    typography: {
        fontFamily: openSans,
        h1: {
            fontFamily: asap
        },
        h2: {
            fontFamily: asap
        },
        h3: {
            fontFamily: asap
        },
        h4: {
            fontFamily: asap
        },
        h5: {
            fontFamily: asap
        },
        h6: {
            fontFamily: asap
        },
        button: {
            fontFamily: asap
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontFamily: openSans
                },
            },
        },
    },
}))
,
accent: {
    light: userTheme.accent?.light,
    main: userTheme.accent?.main,
    dark: userTheme.accent?.dark,
    contrastText: userTheme.accent?.contrastText
}};

export default theme;